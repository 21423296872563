













































































































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Newsletter extends Vue {
  addScript(source: string) {
    return new Promise((resolve) => {
      const exentriqScript = document.createElement('script')
      exentriqScript.onload = () => resolve()
      exentriqScript.setAttribute('async', 'true')
      exentriqScript.setAttribute('src', source)
      document.body.appendChild(exentriqScript)
    })
  }

  async mounted() {
    const { VUE_APP_EXENTRIQ_JQUERY, VUE_APP_EXENTRIQ_VALIDATR, VUE_APP_EXENTRIQ_EMBED_FORM } =
      process.env

    for (const lib of [
      VUE_APP_EXENTRIQ_JQUERY,
      VUE_APP_EXENTRIQ_VALIDATR,
      VUE_APP_EXENTRIQ_EMBED_FORM,
    ]) {
      await this.addScript(lib as string)
    }
  }
}
