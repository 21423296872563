



















































import { Component } from 'vue-property-decorator'
import { contactsService } from '@/services'
import Newsletter from '@/components/molecules/Newsletter.vue'
import Socials from '@/components/molecules/Socials.vue'
import Layout from '@/components/organsims/Layout.vue'
import View from '@/mixins/View'

@Component({
  components: {
    Newsletter,
    Socials,
    Layout,
  },
})
export default class Contacts extends View {
  widgets: any = []

  get section() {
    const locale = this.$t('locale.contacts.title') as string
    return locale.replace(/<\/?[^>]+(>|$)/g, '')
  }

  get contactsRows() {
    return this.getRows(this.widgets)
  }

  getRows(array: any) {
    const result = []
    for (let i = 0; i < array.length; i += 3) result.push(array.slice(i, i + 3))
    return result
  }

  async created() {
    this.widgets = await this.fetch(contactsService)
  }
}
